<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
            <el-form
                :model="CurrencyCategory"
                :rules="rules"
                ref="CurrencyCategoryEditForm"
                label-width="100px"
                size="small"
            >
                <div v-if="show_type">
                    <el-form-item label="类型名" prop="name">
                        <el-input v-model="CurrencyCategory.name" clearable></el-input>
                    </el-form-item>
                </div>
                <div v-else>
                    <el-descriptions
                        class="margin-top"
                        :column="1"
                        :labelStyle="{ width: '120px', 'text-align': 'center' }"
                        :contentStyle="{ 'text-align': 'center' }"
                        border
                    >
                        <el-descriptions-item>
                        <template slot="label">
                            <span>类型名</span>
                        </template>
                        <span>{{CurrencyCategory.name}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
            <div v-if="show_type === 0">
                <el-button
                v-if="$_has(edit_auth)"
                type="primary"
                plain
                @click="$emit('show_edit')"
                >修改</el-button
                >
                <el-button
                v-if="$_has(del_auth)"
                type="danger"
                plain
                @click="CurrencyCategoryDel('CurrencyCategoryEditForm')"
                >删除</el-button
                >
                <el-button plain @click="closeDialog()"
                >关闭</el-button
                >
            </div>
            <div v-else-if="show_type === 1">
                <el-button
                type="success"
                plain
                @click="submitInsert('CurrencyCategoryEditForm')"
                >确定</el-button
                >
                <el-button type="warning" plain @click="resetForm()">重置</el-button>
                <el-button plain @click="closeCurrencyCategoryDialog()"
                >关闭</el-button
                >
            </div>
            <div v-else>
                <el-button
                type="success"
                plain
                @click="submitEdit('CurrencyCategoryEditForm')"
                >确定修改</el-button
                >
                <el-button plain @click="closeDialog()"
                >取消操作</el-button
                >
            </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    currency_category_add_request,
    currency_category_del_request,
    currency_category_edit_request
} from "@/network/finance/CurrencyCategory.js";

export default {
    name: "CurrencyCategoryEditComponent",
    data() {
        return {
            login_loading: false,
            CurrencyCategory: {
                name: '',
                id: '',
            },
            rules: {
                name: [
                    {
                    required: true,
                    message: "客户姓名必填",
                    trigger: "blur",
                    },
                ],
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if (this.show_type === 0) {
                return "印章使用申请类型详情"
            } else if (this.show_type === 1) {
                return "添加印章使用申请类型"
            } else {
                return "修改印章使用申请类型"
            }
        },
    },
    methods: {
        closeDialog() {
            this.resetForm();
            this.$emit("exitDialog")
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return;
                }
                this.CurrencyCategoryInsert(formName);
            });
        },
        CurrencyCategoryInsert(formName) {
            this.login_loading = true;
            currency_category_add_request(this.CurrencyCategory)
            .then((s) => {
                this.login_loading = false;
                if (s.status === 0) {
                this.$message.success("添加成功!");
                this.$emit("exitDialog");
                this.$emit("search");
                this.$emit("details_row", s.result.id * 1);
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                this.login_loading = false;
                this.$message.error(err);
            });
        },
        resetForm() {
            this.CurrencyCategory = {
                name: '',
            };
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return;
                }
                this.CurrencyCategoryEdit(formName);
            });
        },
        CurrencyCategoryEdit(formName) {
            this.login_loading = true;
            this.CurrencyCategory.id = this.id;
            currency_category_edit_request(this.CurrencyCategory)
            .then((s) => {
                this.login_loading = false;
                if (s.status === 0) {
                    this.$message.success("修改成功!");
                    this.$emit("exitDialog");
                    this.$emit("search");
                    this.$emit("details_row", this.CurrencyCategory.id);
                } else {
                    this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                this.login_loading = false;
                this.$message.error(err);
            });
        },
        CurrencyCategoryDel(formName) {
            this.$confirm(
            "是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）",
            "温馨提示",
            {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }
            )
            .then(() => {
                currency_category_del_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success("删除成功!");
                        this.$emit("search");
                    } else {
                        this.$message.error(s.msg);
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                })
                .finally(() => this.closeDialog());
            })
            .catch((err) => {});
        },
        },
        filters: {},
        props: {
            dialogFormVisible: Boolean,
            id: {
                type: Number,
                default() {
                    return 0;
                },
            },
    
        currency_category_details_data: {
            type: Object,
            default() {
                return {};
            },
        },
        show_type: {
            type: Number,
            default() {
                return 0;
            },
        },
        edit_auth: String,
        del_auth: String,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        currency_category_details_data(newVal) {
            this.CurrencyCategory = newVal;
        },
    },
};
</script>

<style lang="less"></style>