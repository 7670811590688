import request from '../request'

//印章使用申请类型列表
export const currency_category_index_request = p => {
    return request({
        method:'GET',
        url:'currency_category/index',
        params: p
    })
}

//新增印章使用申请类型
export const currency_category_add_request = d => {
    return request({
        method:'POST',
        url:'currency_category/add',
        data: d
    })
}

//删除印章使用申请类型
export const currency_category_del_request = id => {
    return request({
        method:'DELETE',
        url:'currency_category/del',
        data: {
            id: id
        }
    })
}

//修改印章使用申请类型
export const currency_category_edit_request = d => {
    return request({
        method:'PUT',
        url:'currency_category/edit',
        data: {
            id: d.id,
            name: d.name
        }
    })
}

//印章使用申请类型详情
export const currency_category_details_request = id => {
    return request({
        method:'GET',
        url:'currency_category/details',
        params: {
            id
        }
    })
}